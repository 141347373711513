import moment from 'moment';
import { AxiosResponse } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import i18n from '../../../i18n';
import { getHoursOffset } from '../../../utils/date';
import { getLanguage } from '../../../utils/language';
import { downloadFile } from '../../../utils/download';
import { randomFourDigits } from '../../../utils/random';
import { getUserSession } from '../../../utils/userSession';
import { GenericParam } from '../../../common/interfaces/commons';
import { analysisStatuses, DATE_FORMATS } from '../../../config/commons';
import { ApiClient, axiosClient as axios } from '../../../utils/axios_instance';
import { openSuccessNotification } from '../../../common/notification/Notification';
import { ANALYSES_URL, ANALYSIS_DETAIL_PDF_URL, SHARED_ANALYSIS_URL, STOCKINGS_URL, USERS_URL } from '../../../config/config.api';

import { Analysis, AnalysisPaginate, AnalysisStatus, Bins, ShareAnalysis, Sowing, User, DataToShare } from './interfaces';

const apiClient: ApiClient = new ApiClient(false);
const defaultHistogram: Bins = {
  6: {
    frequencies: [],
    limits: [],
  },
  7: {
    frequencies: [],
    limits: [],
  },
  8: {
    frequencies: [],
    limits: [],
  },
  9: {
    frequencies: [],
    limits: [],
  },
  10: {
    frequencies: [],
    limits: [],
  },
};

const defaultStocking: Sowing = {
  _id: '',
  companyId: '',
  status: '',
  active: false,
  code: '',
  name: '',
  startDate: '',
  startDateGrowOut: '',
  startDateJuvenile: '',
  naupliusNumber: 0,
  growOutNumber: 0,
  juvenilesNumber: 0,
  litersNumber: 0,
  cubicMeters: 0,
  hectares: 0,
  createdAt: '',
  comment: '',
  harvestQuantity: 0,
  phaseType: '',
  isArchived: false,
  campusId: '',
  campusName: '',
  moduleId: '',
  moduleName: '',
  tankId: '',
  tankName: '',
  maturationCode: '',
  maturationId: {
    _id: '',
    name: '',
  },
  originalCustomStage: {
    key: '',
    value: 0,
  },
  originalStartDate: '',
  daysToInitialStage: 0,
};

const initialState: AnalysisStatus = {
  limit: 0,
  skip: 0,
  total: 0,
  analysis: {
    _id: '',
    code: '',
    companyId: '',
    userId: '',
    createdAt: '',
    completedDate: '',
    coordinatesRemoved: false,
    deviceModel: '',
    nameCompany: '',
    campusName: '',
    campusId: '',
    type: '',
    phaseType: '',
    pin: '',
    analysisIds: [],
    pointSizeRatio: 0,
    inputData: {
      sampleWeight: 0,
      stage: 0,
      maturationId: {
        name: '',
        codes: [],
      }
    },
    resultData: {
      weightGroup: [],
      countGroup: [],
      labels: [],
      averageLength: 0,
      averageWeight: 0,
      averageConditionFactor: 0,
      histogramBins: defaultHistogram,
      histogramLengthBins: defaultHistogram,
      histogramPigmentation: [],
      histogramConditionFactor: [],
      larvaeNumber: 0,
      larvaePerGram: 0,
      standardDeviation: 0,
      uniformity: 0,
      variationCoefficient: 0,
      variationCoefficientLength: 0,
      animalsAboveAverageLength: 0,
      animalsAboveAverageWeight: 0,
      animalsBelowAverageLength: 0,
      animalsBelowAverageWeight: 0,
      animalsAboveConditionFactor: 0,
      animalsBelowConditionFactor: 0,
      lunarAge: 0,
    },
    imageData: {
      original: {
        location: [0, 0],
        url: '',
        key: '',
        dateTime: ''
      },
      reduced: {
        key: '',
        url: ''
      },
      mask: {
        key: '',
        url: ''
      }
    },
    inferenceData: {
      areas: [],
      groups: [],
      coordinates: [],
      weightGroups: {},
    },
    growOutSizes: {
      whole: {
        labels: [],
        ranges: [],
        values: [],
      },
      tail: {
        labels: [],
        ranges: [],
        values: [],
      },
    },
    error: {
      type: '',
      subType: ''
    },
    sowingId: defaultStocking,
    showGrowOutWarning: false,
    isArchived: false,
    hasManualStage: false,
  },
  bin: 6,
  currentIndex: 0,
  hasPublicLink: false,
  listAnalysisPaginate: [],
  isLoading: false,
  isDownloadingFile: false,
  hasPermission: true,
  share: {
    _id: '',
    active: false,
    status: '',
    pin: '',
    expireDate: '',
    title: ''
  },
  allAnalysis: [],
  user: {
    _id: '',
    firstName: '',
    lastName: '',
  },
  isLoadingExtraInformation: false,
  showExtraInformationModal: false,
};

export const detailAnalysisSlice = createSlice({
  name: 'detailAnalysis',
  initialState,
  reducers: {
    setAnalysis: (state: AnalysisStatus, action: PayloadAction<Analysis>) => {
      state.analysis = action.payload;
    },
    serUser: (state: AnalysisStatus, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setAllAnalysis: (state: AnalysisStatus, action: PayloadAction<Analysis[]>) => {
      state.allAnalysis = action.payload;
    },
    setPublicLink: (state: AnalysisStatus, action: PayloadAction<boolean>) => {
      state.hasPublicLink = action.payload;
    },
    setShareStatus: (state: AnalysisStatus, action: PayloadAction<string>) => {
      state.share.status = action.payload;
    },
    setShareAnalysis: (state: AnalysisStatus, action: PayloadAction<ShareAnalysis>) => {
      state.share._id = action.payload._id;
      state.share.active = action.payload.active;
      state.share.pin = action.payload.pin;
      state.share.expireDate = action.payload.expireDate;
      state.share.title = action.payload.title;
    },
    setTotalAnalysis: (state: AnalysisStatus, action: PayloadAction<{ total: number; limit: number; skip: number }>) => {
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.skip = action.payload.skip;
    },
    setAnalysisPaginate: (state: AnalysisStatus, action: PayloadAction<AnalysisPaginate[]>) => {
      state.listAnalysisPaginate = action.payload;
    },

    setIsLoading: (state: AnalysisStatus, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setIsLoadingExtraInformation: (state: AnalysisStatus, action: PayloadAction<boolean>) => {
      state.isLoadingExtraInformation = action.payload;
    },

    setIsDownloadingFile: (state: AnalysisStatus, action: PayloadAction<boolean>) => {
      state.isDownloadingFile = action.payload;
    },

    setStockingName: (state: AnalysisStatus, action: PayloadAction<string>) => {
      state.analysis.sowingId.name = action.payload;
    },
    setDaysToInitialStage: (state: AnalysisStatus, action: PayloadAction<number>) => {
      state.analysis.sowingId.daysToInitialStage = action.payload;
    },

    setTitleShare: (state: AnalysisStatus, action: PayloadAction<string>) => {
      state.share.title = action.payload;
    },

    setHasPermission: (state: AnalysisStatus, action: PayloadAction<boolean>) => {
      state.hasPermission = action.payload;
    },
    setBin: (state: AnalysisStatus, action: PayloadAction<number>) => {
      state.bin = action.payload;
    },
    setCurrentIndex: (state: AnalysisStatus, action: PayloadAction<number>) => {
      state.currentIndex = action.payload;
    },
    setShowExtraInformationModal: (state: AnalysisStatus, action: PayloadAction<boolean>) => {
      state.showExtraInformationModal = action.payload;
    },
  },
});

export const {
  setAnalysis, serUser, setAllAnalysis, setPublicLink, setShareStatus, setShareAnalysis,
  setTotalAnalysis, setAnalysisPaginate, setStockingName,
  setTitleShare, setIsLoading, setIsLoadingExtraInformation, setIsDownloadingFile, setHasPermission,
  setBin, setCurrentIndex, setShowExtraInformationModal,
  setDaysToInitialStage,
} = detailAnalysisSlice.actions;


export const fetchStocking = (params: { stockingId: string; accessToken?: string | null; }) => async (dispatch: Function) => {
  const { accessToken, stockingId } = params;

  const requestParams = { '$select': ['name', 'daysToInitialStage'] };
  let response: AxiosResponse<Sowing>;

  try {
    if (accessToken) {
      response = await axios.get<Sowing>(`${STOCKINGS_URL}/${stockingId}`, {
        headers: { 'Authorization': accessToken },
        params: requestParams
      });
    } else {
      response = await axios.get<Sowing>(`${STOCKINGS_URL}/${stockingId}`, { params: requestParams });
    }

    dispatch(setStockingName(response.data.name));
    dispatch(setDaysToInitialStage(response.data.daysToInitialStage));
  } catch (e) {
    console.log('ERROR' + e);
  }
};

export const fetchAnalysis = (params: { analysisId: string; accessToken?: string }) => async (dispatch: Function) => {
  const { analysisId, accessToken: token } = params;
  dispatch(setIsLoading(true));
  dispatch(setHasPermission(true));
  let data = [];

  const accessToken = localStorage.getItem('accessToken');
  const requestParams = {
    $select: [
      'code', 'type', 'phaseType', 'createdAt', 'inputData',
      'resultData', 'imageData', 'inferenceData', 'error', 'campusId',
      'companyId', 'analysisIds', 'growOutSizes', 'pointSizeRatio', 'showGrowOutWarning',
      'isArchived', 'userId', 'coordinatesRemoved', 'isOffline', 'deviceModel',
      'completedDate', 'hasManualStage', 'unusualAverageWeight',
    ],
    $populate: ['sowingId', 'inputData.maturationId'],
  };

  let response;

  try {
    if (token) {
      response = await axios.get(`${ANALYSES_URL}/${analysisId}`, {
        headers: { 'Authorization': token },
        params: requestParams
      });
    } else if (accessToken) {
      response = await axios.get(`${ANALYSES_URL}/${analysisId}`, { params: requestParams });
    } else {
      response = await apiClient.axios.get(`${ANALYSES_URL}/${analysisId}`, { params: requestParams, });
    }

    if (response.data && response.data.status) {
      dispatch(setIsLoading(false));
      dispatch(setTitleShare(response.data.title));
      dispatch(setShareStatus(response.data.status));
      return;
    }
    data = response.data;

  } catch (e) {
    if (e.response?.data?.data?.error) {
      dispatch(setIsLoading(false));
      dispatch(setShareStatus(e.response.data.data.error));
    }

    if (e.response?.status === 403) {
      dispatch(setHasPermission(false));
    }
    return;
  }

  dispatch(setAnalysis(data));
  dispatch(setAllAnalysis(data.allAnalysis));
  dispatch(setTitleShare(data.title));
  dispatch(setIsLoading(false));
};

export const fetchAllAnalysis = (params: { stockingId: string; analysisId: string; page: number; companyId: string }) => async (dispatch: Function) => {
  const { stockingId, analysisId, page, companyId } = params;
  dispatch(setIsLoading(true));

  let skip = 0;
  if (page !== 0) {
    skip = (page - 1);
  }

  try {
    const dataAnalysis = await getAnalysis({ stockingId, analysisId, companyId });
    skip = dataAnalysis.skip;
    dispatch(setAnalysisPaginate(dataAnalysis.data));

    const requestParams = {
      $limit: 1,
      $skip: skip,
      '$sort[inputData.stage]': 1,
      '$sort[createdAt]': 1,
      status: analysisStatuses.COMPLETED,
      companyId: companyId,
      $select: ['code', 'createdAt', 'inputData', 'resultData', 'imageData', 'inferenceData', 'error'],
      $populate: ['sowingId', 'inputData.maturationId'],
      sowingId: stockingId,
      isArchived: false,
    };

    const response = await axios.get(ANALYSES_URL, { params: requestParams });
    dispatch(setTotalAnalysis(response.data));
  } catch (e) {
    console.log('ERROR');
  }
  dispatch(setIsLoading(false));
};

async function getAnalysis (params: { stockingId: string; analysisId: string; companyId: string }) {
  const { stockingId, analysisId, companyId } = params;
  let skip = 0;

  const requestParams = {
    '$limit': -1,
    '$sort[inputData.stage]': 1,
    'status': analysisStatuses.COMPLETED,
    'companyId': companyId,
    'sowingId': stockingId,
    '$select': ['_id', 'inputData.stage', 'type'],
    isArchived: false,
  };

  const response = await axios.get<AnalysisPaginate[]>(ANALYSES_URL, { params: requestParams });

  for (let index = 0; index < response.data.length; index++) {
    if (response.data[index]._id === analysisId) {
      skip = index;
    }
  }

  return { skip: skip, data: response.data };
}

export const fetchPinAnalysis = (params: { analysisId: string; pin: string }) => async (dispatch: Function) => {
  const { analysisId, pin } = params;
  dispatch(setShareStatus(''));
  dispatch(setIsLoading(true));
  let analysis;

  try {
    const accessToken = localStorage.getItem('accessToken');
    const body = { 'pin': pin };

    let response;
    if (accessToken) {
      response = await axios.post(`${ANALYSES_URL}/${analysisId}`, body);
    } else {
      response = await apiClient.axios.post(`${ANALYSES_URL}/${analysisId}`, body);
    }
    analysis = response.data;
    analysis.pin = pin;

  } catch (e) {
    if (e.response.data.data.error) {
      dispatch(setIsLoading(false));
      dispatch(setShareStatus(e.response.data.data.error));
    }
    console.log('ERROR');
    return;
  }

  dispatch(setAnalysis(analysis));
  dispatch(setAllAnalysis(analysis.allAnalysis));
  dispatch(setIsLoading(false));
};

export const storeSettings = async (dataToShare: DataToShare, generatePDF?: boolean) => {
  try {
    const userSession = getUserSession();
    dataToShare.userId = userSession._id;

    await axios.post(`${SHARED_ANALYSIS_URL}`, dataToShare);
    if (generatePDF) {
      openSuccessNotification(i18n.t('share.pdf.created'));
    } else {
      openSuccessNotification(i18n.t('share.config.created'));
    }
  } catch (e) {
    console.log('ERROR');
  }
};

export const updateSettings = async (shareAnalysisId: string, body: DataToShare, generatePDF?: boolean) => {
  const { analysisId, active, title, expireDate, pin } = body;
  const data = {
    analysisId,
    active,
    title,
    expireDate,
    pin,
  };
  try {
    await axios.patch(`${SHARED_ANALYSIS_URL}/${shareAnalysisId}`, data);
    if (generatePDF) {
      openSuccessNotification(i18n.t('share.pdf.updated'));
    } else {
      openSuccessNotification(i18n.t('share.config.updated'));
    }
  } catch (e) {
    console.log('ERROR');
  }
};

export const fetchShareAnalysis = (params: { analysisId: string; generatePDF?: boolean }) => async (dispatch: Function) => {
  const { analysisId, generatePDF } = params;
  let response: AxiosResponse<GenericParam> | undefined = undefined;

  try {
    dispatch(setPublicLink(false));
    response = await axios.post(`${SHARED_ANALYSIS_URL}/${analysisId}`);

    if (!response?.data) {
      return;
    }

    const share = { _id: response.data._id, active: response.data.active, title: response.data.title, pin: response.data.pin, expireDate: response.data.expireDate } as ShareAnalysis;
    dispatch(setShareAnalysis(share));
    if (response.data.active) {
      dispatch(setPublicLink(true));
    }

    if (generatePDF) {
      const expireDate = getExpireDate();
      const pin = response.data.pin ?? randomFourDigits().toString();
      const dataToShare = { analysisId: analysisId, active: true, pin, expireDate, title: response.data.title };

      dispatch(setIsDownloadingFile(true));
      updateSettings(response.data._id, dataToShare, generatePDF);
    }

  } catch (e) {
    console.log(e?.response.data);
  }

  try {
    if (response?.data) {
      return;
    }

    const share = { _id: '', active: false, pin: '', status: '', expireDate: '', title: '' };
    dispatch(setShareAnalysis(share));
    dispatch(setPublicLink(false));

    if (generatePDF) {
      const expireDate = getExpireDate();
      const pin = randomFourDigits().toString();
      const dataToShare = { analysisId: analysisId, active: true, expireDate, pin };

      dispatch(setIsDownloadingFile(true));
      storeSettings(dataToShare, generatePDF);
    }
  } catch (e) {
    console.log(e?.response.data);
  }
};

const getExpireDate = () => {
  const value = moment().add(4, 'M').format(DATE_FORMATS.YYYY_MM_DD).toString();
  return value;
};

export const resetShareAnalysis = () => (dispatch: Function) => {
  dispatch(setShareAnalysis(initialState.share));
  dispatch(setShareStatus(''));
  dispatch(setPublicLink(false));
};

export const resetAnalysis = () => (dispatch: Function) => {
  dispatch(setAnalysis(initialState.analysis));
  dispatch(setAllAnalysis([]));
};

export const fetchUrlAnalysisDetailPdf = (params: { bin: number; analysisId: string; analysisCode: string }) => async (dispatch: Function) => {
  const { bin, analysisId, analysisCode } = params;

  try {
    const response = await axios.get(ANALYSIS_DETAIL_PDF_URL);

    const language = getLanguage();
    const accessToken = localStorage.getItem('accessToken');
    const url = `${response.data.url}?bin=${bin}&analysisId=${analysisId}&accessToken=${accessToken}&language=${language}&hoursOffset=${getHoursOffset()}`;
    await downloadFile(url, analysisCode, 'pdf');

  } catch (e) {
    console.log(e.response);
  }
  dispatch(setIsDownloadingFile(false));
};

export const fetchUser = (props: { userId: string }) => async (dispatch: Function) => {
  const { userId } = props;

  const url = `${USERS_URL}/${userId}`;
  const params = {
    '$select': ['firstName', 'lastName'],
  };

  try {
    const response = await axios.get<User>(url, { params });
    dispatch(serUser(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export default detailAnalysisSlice.reducer;
